import React from 'react'
import homeImg from "../../assets/images/Home.png"
import "./home.css"

const Home = () => {
  return (

    <>
    <section id='Home'>
    <div className=' home' >
      <div>

      <img src={homeImg} alt="" />
        
      </div>
        
    </div>
    </section>
    
    </>
  )
}

export default Home
