import pdtImage1 from '../images/one.png';
import pdtImage2 from '../images/two.png';
import pdtImage3 from '../images/three.png';
import pdtImage4 from '../images/four.png';


const teamsData = [
    {
        id: 1,
        Title: 'Team',
        img: pdtImage1,
        team_info: <div>
            <p>
                •  Mr. Sarthak Saoji - Managing Director <br />
                •  Mr. Mihir Vyawahare - Director <br />
                •  Dr. Sudhir Pandya - Additional Director <br />
            </p>
        </div>,
    },
    {
        id: 2,
        Title: 'Mentor',
        img: pdtImage2,
        team_info: <div>
            <p>
            •  Mr. Prashant  Saboo <br />
            •  Mr. Girish Rathi <br />
            •  Dr. Niraj Vyawahare <br />
            •  Dr. Sangita Sangvikar <br />
            </p>
            
        </div>,
    },
    {
        id: 3,
        Title: 'Vision',
        img: pdtImage3,
        team_info:
            <div>
                <p>"Transforming dermatological care through innovation and improving lives with exceptional skincare solutions."</p>
                
            </div>
        ,
    },
    {
        id: 4,
        Title: 'Why Dermatology',
        img: pdtImage4,
        team_info:
            <div>
                <p>Dermatology is a field that focuses on the study, diagnosis, and treatment of skin-related conditions and concerns. Given that the skin is both the largest sensory organ in the human body and the most visible, it is of utmost importance. As the outermost layer, the skin acts as a protective barrier, shielding our internal organs from external elements. Individuals depend on their skin's health and appearance since their skin's condition frequently indicates how they are feeling overall.
                <br />
                <br />
                Beauty and self-care are becoming increasingly important in today's society.  Many individuals are conscious of their appearance and seek dermatological solutions to address various skin concerns. Whether it's acne, eczema, psoriasis, or aging-related issues, dermatology plays a vital role in diagnosing, treating, and managing these conditions. By providing effective treatments and interventions, dermatologists can significantly improve the quality of life for individuals who are impacted by these skin conditions.
                <br />
                <br />
                Dermatology is a field that encompasses both medical and aesthetic aspects. On one hand, dermatologists diagnose and treat medical conditions such as skin cancer, infections, and inflammatory disorders. On the other hand, they offer a range of cosmetic treatments that help individuals achieve their desired appearance and boost their self-confidence. This combination of medical and aesthetic expertise makes dermatology a diverse and multidimensional field.
                Furthermore, dermatology takes a holistic approach to skin health. It considers factors such as lifestyle, nutrition, and environmental influences in addition to medical interventions. Dermatologists emphasize the significance of keeping healthy skin through appropriate care, prevention, and early diagnosis of potential disorders in order to enhance overall wellbeing. This comprehensive approach ensures that individuals receive the necessary guidance and support to achieve and maintain optimal skin health.
                </p>
                
            </div>

        ,
    },
   
]


export default teamsData;